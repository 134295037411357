import {ReduceStore} from 'flux/utils';
import MeasuresMetaStore from './MeasuresMetaStore';
import MeasuresDataActions from './MeasuresDataActions';
import MeasuresMetaActions from './MeasuresMetaActions';
import FiltersActions from './FiltersActions';
import FiltersStore from './FiltersStore';
import Dispatcher from '../Dispatcher';
import api from '../../lib/api';
import debug from '../../lib/debug';
import AsyncStatusHelpers, {AsyncStatusTypes} from './utils/AsyncStatusHelpers';
import LocationStore from "../LocationStore";
import LocationActions from "../LocationActions";

class MeasuresDataStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            data: {},
            status: "init" // "loading", "loaded", "error", "init"
        };
    }

    reduce(state, action) {
        let match;
        switch (action.type) {
            case FiltersActions.change:
            case MeasuresMetaActions.load:
            case LocationActions.LOCATION_CHANGED: {
                this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
                if(!["DASHBOARD"].includes(LocationStore.getRouteKey())) {
                    return state; // we only need this data for the above views.
                }
                this.__dispatcher.waitFor([
                    FiltersStore.getDispatchToken()
                ]);
                match = LocationStore.getMatch();

                if (match && match.params) {
                    const year = match.params.year;
                    const orgKey = match.params.orgKey;
                    const implKey = match.params.implKey;
                    if (
                        MeasuresMetaStore.isLoaded() &&
                        FiltersStore.isLoaded() &&
                        (
                            action.type === MeasuresMetaActions.load
                            || action.filterKey === "year"
                            || action.filterKey === "participants"
                            || action.filterKey === "physicians"
                        )
                    ) {
                        state = {...state, status: AsyncStatusTypes.loading};
                        const filters = FiltersStore.getAllFiltersValue(true);
                        api.loadDashboardMeasuresYearData(year, orgKey, implKey, filters).then((res) => {
                            Dispatcher.dispatch({
                                type: MeasuresDataActions.load,
                                data: res.data
                            });
                            return Promise.resolve(res);
                        }).catch((error) => {
                            Dispatcher.dispatch({
                                type: MeasuresDataActions.error
                            });
                            return Promise.reject(error);
                        });
                    }
                }
                return state;
            }
            case MeasuresDataActions.load: {
                state = {
                    data: calculateMeasuresData(action.data),
                    status: AsyncStatusTypes.loaded
                };
                return state;
            }
            case MeasuresDataActions.error: {
                state = {...state, status: AsyncStatusTypes.error};
                return state;
            }
            default:
                return state;
        }
    }

    isLoaded() {
        return AsyncStatusHelpers.isLoaded(this.getState());
    }

    isError() {
        return AsyncStatusHelpers.isError(this.getState());
    }

    getAllMeasuresData() {
        return this.getState().data;
    }

    hasMeasureDataById(id) {
        return this.getState().data.hasOwnProperty(id);
    }

    getMeasureDataById(id) {
        const measuresMap = this.getState().data;
        if(!measuresMap.hasOwnProperty(id)) {
            debug.warn(`MeasuresDataStore.getMeasureDataById(): unknown measure id "${id}"`, Object.keys(measuresMap));
            return false;
        }
        return measuresMap[id];
    }
}


const calculateMeasuresData = (data) => {
    const result = {};
    const allMeasures = MeasuresMetaStore.getAllMeasuresMeta();
    allMeasures.forEach((m) => {
        try {
            result[m.id] = calculateMeasureRowData(data, m.id);
        }
        catch(err) {
            debug.warn(`MeasureDataStore.calculateMeasuresData() there was a problem calculating the data for measure id "${m.id}"`, err);
        }
    });
    return result;
};

export const calculateMeasureRowData = (data, measureId) => {
    const measure = MeasuresMetaStore.getMeasureMetaById(measureId);
    let row;
    if(measure.algorithm === "overallStratumOnly") {
        // find the component with the title "overall" and use its values
        const overallMeasureId = measure.components.find(mId => {
            const childMeasure = MeasuresMetaStore.getMeasureMetaById(mId);
            return childMeasure.title === "overall";
        });
        if(!overallMeasureId) {
            debug.warn(`MeasureDataStore.calculateMeasuresData() "${measureId}" has no overall child component (ERROR: overallStratumOnly)`);
            return {};
        }
        return calculateMeasureRowData(data, overallMeasureId); // recursive
    }
    else if(measure.algorithm === "simpleAverage") {
        // loop over all subComponents get calculate performanceRate
        const rows = measure.subComponents.map(mId => {
            return calculateMeasureRowData(data, mId); // recursive
        });

        // SUM subComponents
        row = {};
        rows.forEach((subComponentData) => {
            Object.keys(subComponentData).forEach((key) => {
                const subComponentCell = subComponentData[key];
                let cell = row[key];
                if(!cell) {
                    cell = {
                        key: key,
                        eligible: 0,
                        exclusion: 0,
                        exception: 0,
                        notMet: 0,
                        met: 0,
                        performanceRate: 0,
                        reportingRate: 0
                    };
                    row[key] = cell;
                }
                ["performanceRate", "eligible", "exclusion", "exception", "notMet", "met"]
                    .forEach((k) => cell[k] += subComponentCell[k]);
            });
        });

        // then average each performanceRate result
        // average performance rate
        Object.keys(row).forEach((key) => {
            let cell = row[key];
            cell.performanceRate = cell.performanceRate / rows.length; // average
            cell.reportingRate = ((cell.met + cell.exception + cell.notMet) / cell.eligible) * 100;
        });
    }
    else if(measure.algorithm === null) {
        // base case calculate performanceRate
        row = getMeasureDataById(data, measureId);
        if (row) {
            Object.keys(row).forEach((key) => {
                const cell = row[key];
                cell.performanceRate = (cell.met/(cell.met + cell.notMet) * 100);
                cell.reportingRate = ((cell.met + cell.exception + cell.notMet) / cell.eligible) * 100;
            });
        }
        else {
            debug.warn(`MeasureDataStore.calculateMeasureRowData(): unknown measureId ${measureId}`);
        }
    }

    return row;
};


const getMeasureDataById = (data, id) => {
    if(!data.hasOwnProperty(id)) {
        throw new Error(`MeasureDataStore.getMeasureDataById() unknown measure data id "${id}". Available id's [${Object.keys(data).map(key=>key).join(', ')}]`);
    }
    return data[id];
};


export default new MeasuresDataStore();
